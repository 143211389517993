const TermsAndConditions = () => {
    return (
        <div className="flex justify-center items-center min-h-screen p-4">
            <div className="max-w-4xl bg-white p-8 shadow-md rounded-lg text-left">
                <h1 className="text-3xl font-bold mb-4 text-center">Términos y Condiciones de uso Deportsen</h1>
                <p className="mb-4 text-center">Fecha de entrada en vigor: 01/02/2024</p>

                <p className="mb-4">
                    Bienvenidos a Deportsen, la aplicación de entrenamiento para futbolistas. Antes de comenzar a utilizar nuestros servicios, le invitamos a revisar detenidamente nuestros Términos y Condiciones. Estos términos rigen el uso de nuestra aplicación y establecen las reglas y pautas que se aplican a su interacción con Deportsen.
                </p>
                <p className="mb-4">
                    Al utilizar Deportsen, usted acepta plenamente y sin reservas estos Términos y Condiciones. Le recomendamos que los lea detenidamente para comprender sus derechos y responsabilidades al utilizar nuestra aplicación.
                </p>
                <p className="mb-4">
                    Nuestra prioridad es proporcionar una experiencia de entrenamiento de alta calidad y segura para todos nuestros usuarios. A través de estos Términos y Condiciones, buscamos establecer una relación clara y justa entre usted y Deportsen.
                </p>
                <p className="mb-4">
                    Si tiene alguna pregunta o inquietud con respecto a nuestros Términos y Condiciones, no dude en ponerse en contacto con nuestro equipo de soporte a través de <a href="mailto:support@deportsen.com" className="text-blue-500 underline">support@deportsen.com</a>. Estamos aquí para ayudar y proporcionar la información que necesite para disfrutar de una experiencia segura y satisfactoria en Deportsen.
                </p>
                <p className="mb-4">
                    Gracias por unirse a Deportsen y por confiar en nosotros para su entrenamiento. Esperamos que disfrute de nuestra aplicación y que alcance sus metas de entrenamiento de fútbol de manera efectiva y divertida.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">1. Modificaciones</h2>
                <p className="mb-4">
                    Nos reservamos el derecho de modificar estas términos y condiciones en cualquier momento. Te recomendamos revisar esta página regularmente para estar al tanto de los cambios. El uso continuado de la aplicación después de los cambios constituye tu aceptación de los términos modificados.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">2. Uso de la aplicación</h2>
                <p className="mb-4">
                    Los Términos y Condiciones de Deportsen definen las reglas y restricciones para el uso de nuestra aplicación. A continuación, se establecen las normas de uso de la aplicación, incluyendo los límites de descarga, el acceso a ejercicios y planes para usuarios no premium:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Acceso gratuito a la aplicación:</strong> Deportsen permite el acceso gratuito a la aplicación para todos los usuarios, lo que incluye la posibilidad de explorar una selección de ejercicios y características disponibles. Los usuarios no están obligados a adquirir una suscripción Premium para utilizar la aplicación.
                    </li>
                    <li>
                        <strong>Límites de descarga:</strong> Los usuarios que no sean suscriptores Premium están sujetos a límites de descarga de ejercicios. Estos límites pueden variar y están diseñados para garantizar un uso justo de la aplicación. Los usuarios pueden descargar un número limitado de ejercicios de manera gratuita. Para superar estos límites y acceder a una descarga ilimitada, pueden optar por suscribirse a una suscripción Premium a través de Google Play.
                    </li>
                    <li>
                        <strong>Acceso a ejercicios y planes:</strong> Los usuarios que no sean suscriptores Premium tienen acceso a una selección de ejercicios que se ofrecen de forma gratuita. Sin embargo, algunos ejercicios y planes de entrenamiento más avanzados y personalizados pueden estar disponibles solo para los suscriptores Premium. Los usuarios pueden desbloquear todo el contenido y funciones al adquirir una suscripción Premium a través de Google Play.
                    </li>
                    <li>
                        <strong>Disponibilidad de funciones premium:</strong> Los usuarios que adquieran una suscripción Premium disfrutarán de beneficios adicionales, como la eliminación de anuncios, acceso ilimitado a ejercicios y planes, así como siete días de prueba para nuevos usuarios. Estas características Premium se activarán automáticamente después de la suscripción.
                    </li>
                    <li>
                        <strong>Respeto de los límites de descarga:</strong> Es importante que los usuarios respeten los límites de descarga y los términos y condiciones de la aplicación. El incumplimiento de estos términos puede dar lugar a la suspensión o restricción de la cuenta del usuario.
                    </li>
                </ul>
                <p className="mb-4">
                    Estas normas de uso de la aplicación están diseñadas para garantizar una experiencia equitativa para todos los usuarios y fomentar la sostenibilidad de la aplicación.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">3. Suscripción premium</h2>
                <p className="mb-4">
                    Los Términos y Condiciones de Deportsen explican en detalle los beneficios de la suscripción Premium, que incluyen ventajas exclusivas para los usuarios que optan por esta opción. A continuación, se detallan los beneficios de la suscripción Premium:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Eliminación de anuncios:</strong> Los suscriptores Premium disfrutan de una experiencia sin anuncios en la aplicación Deportsen. Esto significa que los anuncios publicitarios, como videos y banners, se eliminan por completo, lo que permite una experiencia de usuario ininterrumpida y sin distracciones.
                    </li>
                    <li>
                        <strong>Acceso ilimitado a contenido:</strong> Los suscriptores Premium tienen acceso ilimitado a todos los ejercicios, planes de entrenamiento y características disponibles en la aplicación. Pueden explorar y utilizar todo el contenido de la aplicación sin restricciones, lo que les permite personalizar su experiencia de entrenamiento de acuerdo a sus necesidades y preferencias.
                    </li>
                    <li>
                        <strong>Siete días de prueba para nuevos usuarios:</strong> Los nuevos usuarios tienen la oportunidad de probar la suscripción Premium de Deportsen de forma gratuita durante siete días. Esto les permite experimentar todos los beneficios de la suscripción antes de tomar una decisión de pago.
                    </li>
                </ul>
                <p className="mb-4">
                    Es importante destacar que la activación de la suscripción Premium es en su mayoría automática. Sin embargo, el tiempo que puede llevar la activación puede variar según la ubicación del usuario y la latencia de los servidores. En general, cuanto más cerca se encuentre el usuario de nuestros servidores, más rápida será la activación, y en muchos casos, puede ser prácticamente instantánea.
                </p>
                <p className="mb-4">
                    Deportsen se esfuerza por garantizar que los usuarios disfruten de los beneficios de la suscripción Premium de manera oportuna y sin inconvenientes. Si bien la activación es en su mayoría automática, puede haber situaciones excepcionales en las que se requiera un tiempo adicional para que el proceso se complete.
                </p>
                <p className="mb-4">
                    Si los usuarios tienen preguntas adicionales sobre la suscripción Premium, la eliminación de anuncios o la activación de la cuenta, pueden ponerse en contacto con nosotros a través de nuestro correo de soporte: <a href="mailto:support@deportsen.com" className="text-blue-500 underline">support@deportsen.com</a>.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">4. Pago y notificaciones de suscripción</h2>
                <p className="mb-4">
                    Los Términos y Condiciones de Deportsen detallan el proceso de suscripción, cancelación y renovación, así como las notificaciones relacionadas con las suscripciones. A continuación, se explica este proceso en detalle:
                </p>
                <h3 className="text-xl font-semibold mb-2 mt-4">Proceso de suscripción:</h3>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Adquisición de una suscripción premium:</strong> Para adquirir una suscripción Premium en Deportsen, los usuarios deben realizar una transacción a través de Google Play. Esto implica seleccionar la suscripción deseada y proporcionar la información de pago requerida.
                    </li>
                    <li>
                        <strong>Confirmación de la suscripción:</strong> Una vez que se completa la transacción, Google Play confirma la suscripción y proporciona a los usuarios acceso a los beneficios Premium en Deportsen.
                    </li>
                </ul>
                <h3 className="text-xl font-semibold mb-2 mt-4">Cancelación de suscripción:</h3>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Cancelación por el usuario:</strong> Los usuarios tienen la opción de cancelar su suscripción Premium en cualquier momento a través de Google Play. La cancelación se puede realizar en la configuración de la cuenta de Google Play Store.
                    </li>
                    <li>
                        <strong>Notificación de cancelación:</strong> Después de la cancelación, los usuarios recibirán una notificación por correo electrónico para confirmar que su suscripción Premium ha sido cancelada con éxito.
                    </li>
                </ul>
                <h3 className="text-xl font-semibold mb-2 mt-4">Renovación de suscripción:</h3>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Renovación automática:</strong> La suscripción Premium en Deportsen se renueva automáticamente al final del período de suscripción actual. Los usuarios pueden optar por desactivar la renovación automática en la configuración de la cuenta de Google Play.
                    </li>
                    <li>
                        <strong>Notificación de renovación:</strong> Los usuarios recibirán notificaciones por correo electrónico antes de la fecha de renovación de su suscripción Premium para informarles sobre la próxima renovación. Esto les permite tomar decisiones informadas sobre si desean continuar con la suscripción.
                    </li>
                </ul>
                <p className="mb-4">
                    Es importante señalar que la gestión de las suscripciones, incluida la adquisición, cancelación y renovación, se realiza a través de Google Play, que tiene sus propias políticas de suscripción y términos de servicio. Los usuarios deben revisar y comprender las políticas de Google Play en relación con las suscripciones.
                </p>
                <p className="mb-4">
                    Deportsen se compromete a notificar a los usuarios sobre las transacciones y cambios en sus suscripciones a través de notificaciones por correo electrónico. Estas notificaciones son una parte importante de nuestro proceso para mantener a los usuarios informados y darles control sobre sus suscripciones.
                </p>
                <p className="mb-4">
                    Si los usuarios tienen preguntas adicionales sobre el proceso de suscripción, cancelación, renovación o las notificaciones de suscripción, pueden ponerse en contacto con nosotros a través de nuestro correo de soporte: <a href="mailto:support@deportsen.com" className="text-blue-500 underline">support@deportsen.com</a>.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">5. Propiedad intelectual</h2>
                <p className="mb-4">
                    Deportsen valora y protege los derechos de propiedad intelectual relacionados con su contenido, imágenes, videos y logotipos. A través de nuestros Términos y Condiciones, establecemos claramente los derechos y responsabilidades en relación con la propiedad intelectual. A continuación, aclaramos los aspectos clave relacionados con la propiedad intelectual en Deportsen:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Derechos de autor:</strong> Todo el contenido, incluyendo, pero sin limitarse a, imágenes, videos, texto, gráficos y otros materiales, presentes en la aplicación Deportsen, está protegido por las leyes de derechos de autor. Todos los derechos de autor y derechos de propiedad intelectual están reservados y pertenecen a Deportsen o a sus licenciantes.
                    </li>
                    <li>
                        <strong>Licencia de uso:</strong> Deportsen concede a los usuarios una licencia limitada, no exclusiva y no transferible para utilizar el contenido de la aplicación con el propósito de entrenamiento y uso personal. Los usuarios no pueden reproducir, distribuir, modificar, mostrar públicamente, vender ni crear trabajos derivados del contenido sin el consentimiento explícito de Deportsen.
                    </li>
                    <li>
                        <strong>Marcas comerciales y logotipos:</strong> Todas las marcas comerciales, logotipos y marcas de servicio utilizadas en Deportsen son propiedad de Deportsen o de terceros licenciantes. Los usuarios no pueden utilizar estas marcas sin el consentimiento previo por escrito de Deportsen o los propietarios de las marcas respectivas.
                    </li>
                    <li>
                        <strong>Uso responsable:</strong> Los usuarios se comprometen a utilizar el contenido y los materiales de Deportsen de manera responsable y de acuerdo con los Términos y Condiciones establecidos. Cualquier uso no autorizado, incluyendo la distribución no autorizada o la explotación comercial, está estrictamente prohibido.
                    </li>
                    <li>
                        <strong>Denuncias de infracción de derechos de autor:</strong> Deportsen se compromete a responder a las denuncias de infracción de derechos de autor de manera oportuna y a tomar medidas, incluyendo la eliminación de contenido infractor, de acuerdo con la ley aplicable.
                    </li>
                    <li>
                        <strong>Protección de contenido: </strong>Todo el contenido, diseño, código y funcionalidades de la aplicación, incluidas las tecnologías de inteligencia artificial implementadas, están protegidos por derechos de propiedad intelectual. El usuario no está autorizado a copiar, modificar, distribuir, descompilar, realizar ingeniería inversa ni explotar de ninguna manera el contenido o las funcionalidades de la aplicación sin nuestro permiso expreso por escrito.
                    </li>
                </ul>
                <p className="mb-4">
                    Los derechos de propiedad intelectual en Deportsen están protegidos de acuerdo con las leyes y regulaciones aplicables. Respetamos los derechos de propiedad intelectual de terceros y esperamos que los usuarios de nuestra aplicación hagan lo mismo. Cualquier infracción de los derechos de propiedad intelectual puede dar lugar a la terminación de la cuenta del usuario y a acciones legales según corresponda.
                </p>
                <p className="mb-4">
                    Si los usuarios tienen preguntas adicionales sobre la propiedad intelectual en Deportsen o desean presentar una denuncia de infracción de derechos de autor, pueden ponerse en contacto con nosotros a través de nuestro correo de soporte: <a href="mailto:support@deportsen.com" className="text-blue-500 underline">support@deportsen.com</a>.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">6. Idioma de los documentos</h2>
                <p className="mb-4">
                    Los Términos y Condiciones, así como la Política de Privacidad de Deportsen, están disponibles únicamente en español. Esto significa que los documentos oficiales que rigen la relación entre Deportsen y sus usuarios, incluyendo los términos de uso y las prácticas de privacidad, se presentan exclusivamente en español.
                </p>
                <p className="mb-4">
                    La elección de un único idioma para estos documentos se realiza para garantizar la coherencia y claridad en la comunicación de las reglas y políticas de Deportsen. Los usuarios pueden acceder a estos documentos a través de la aplicación y en la página web oficial de Deportsen en deportsen.com.
                </p>
                <p className="mb-4">
                    Si los usuarios tienen preguntas o necesitan aclaraciones sobre los Términos y Condiciones o la Política de Privacidad en español, pueden ponerse en contacto con nosotros a través de nuestro correo de soporte: <a href="mailto:support@deportsen.com" className="text-blue-500 underline">support@deportsen.com</a>.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">7. Presencia de anuncios y notificaciones push</h2>
                <p className="mb-4">
                    En Deportsen, es importante informar a nuestros usuarios sobre la presencia de anuncios y notificaciones push en la aplicación. A través de nuestros Términos y Condiciones, aclaramos los detalles relacionados con estos aspectos:
                </p>
                <h3 className="text-xl font-semibold mb-2 mt-4">Proceso de suscripción:</h3>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Presencia de anuncios:</strong> Los usuarios deben ser conscientes de que Deportsen incluye anuncios en la aplicación. Estos anuncios pueden presentarse en forma de videos y banners publicitarios. Los anuncios son una fuente de apoyo financiero para mantener la aplicación gratuita para los usuarios que no sean suscriptores Premium.
                    </li>
                    <li>
                        <strong>Notificaciones push:</strong> Deportsen utiliza notificaciones push para comunicarse con los usuarios de la aplicación. Estas notificaciones pueden incluir mensajes, recordatorios de entrenamiento, motivación y otros anuncios relevantes. Las notificaciones push son una herramienta importante para mantener a los usuarios informados y comprometidos.
                    </li>
                    <li>
                        <strong>Eliminación de anuncios:</strong> La única forma de evitar la visualización de anuncios en Deportsen es adquirir una suscripción Premium a través de Google Play. Los suscriptores Premium disfrutan de una experiencia sin anuncios, lo que garantiza una navegación sin distracciones.
                    </li>
                    <li>
                        <strong>Control de notificaciones push:</strong> Los usuarios tienen el control sobre las notificaciones push que desean recibir. Pueden personalizar sus preferencias de notificación a través de la configuración de la aplicación.
                    </li>
                </ul>
                <p className="mb-4">
                    Deportsen se esfuerza por equilibrar la presentación de anuncios y notificaciones push de manera que no resulten intrusivos para los usuarios. Los anuncios y notificaciones push son una parte importante de cómo mantenemos la aplicación gratuita para aquellos usuarios que no optan por la suscripción Premium.
                </p>
                <p className="mb-4">
                    Si los usuarios tienen preguntas adicionales sobre la presencia de anuncios, las notificaciones push o desean obtener más información sobre cómo eliminar anuncios a través de la suscripción Premium, pueden ponerse en contacto con nosotros a través de nuestro correo de soporte: <a href="mailto:support@deportsen.com" className="text-blue-500 underline">support@deportsen.com</a>.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">8. Uso de la Inteligencia Artificial</h2>
                <p className="mb-4">
                    La aplicación incluye herramientas basadas en inteligencia artificial (IA) que brindan información y recomendaciones en diversas áreas. El usuario entiende y acepta que estas recomendaciones se generan mediante algoritmos automatizados, y si bien la IA está diseñada para ofrecer información útil y relevante, no reemplaza el asesoramiento profesional especializado.
                    La IA en la aplicación es solo una herramienta complementaria y no debe ser la única base para tomar decisiones críticas de salud, legales, financieras o de cualquier otra índole. Se recomienda consultar a profesionales debidamente calificados en cada área para obtener asesoramiento adecuado.
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Limitación de Responsabilidad: </strong> El usuario comprende y acepta que la información proporcionada por los sistemas de IA puede no ser completamente precisa, completa o actualizada. No asumimos responsabilidad alguna por cualquier decisión o acción tomada por el usuario basada en las recomendaciones de la IA o por cualquier error, omisión o malentendido de la información generada.
                        Bajo ninguna circunstancia seremos responsables por daños directos, indirectos, incidentales, especiales o consecuentes que surjan del uso o la imposibilidad de utilizar la aplicación o cualquier funcionalidad basada en IA.
                    </li>
                    <li>
                        <strong>No Suministro de Servicios Médicos, Financieros o Legales: </strong>Nuestra aplicación no proporciona servicios médicos, financieros, legales ni de cualquier otra índole profesional. Las herramientas basadas en IA que se ofrecen tienen un carácter meramente informativo y no deben interpretarse como diagnóstico, tratamiento médico, asesoramiento financiero o legal, o cualquier otro servicio profesional. El usuario debe consultar con un profesional calificado para obtener asesoramiento personalizado en cualquier caso.
                    </li>
                    <li>
                        <strong>Uso Responsable: </strong>El usuario es responsable de asegurarse de que utiliza la aplicación de manera coherente con sus necesidades y con su capacidad de interpretación de la información proporcionada por los asistentes de IA. Es deber del usuario verificar y contrastar la información recibida con otras fuentes o expertos en la materia antes de tomar decisiones basadas en las recomendaciones de la aplicación.
                    </li>
                    <li>
                        <strong>Actualizaciones y Modificaciones de la IA: </strong>La tecnología de IA implementada en la aplicación puede ser actualizada, modificada o retirada en cualquier momento, sin previo aviso, y sin que ello implique la obligación de mantener cualquier funcionalidad en particular. Nos reservamos el derecho de modificar, suspender o discontinuar cualquier aspecto o funcionalidad de la aplicación en cualquier momento.
                    </li>
                </ul>
                <h2 className="text-xl font-bold mb-2 mt-6">9. Edad mínima para el uso de la aplicación</h2>
                <p className="mb-4">
                    En Deportsen, establecemos una edad mínima para el uso de nuestra aplicación. Los usuarios deben tener al menos 13 años de edad para poder registrarse y utilizar nuestra plataforma. Esta restricción se aplica de acuerdo con nuestra política y cumple con las regulaciones de privacidad de datos y protección de menores.
                </p>
                <p className="mb-4">
                    La decisión de establecer esta edad mínima se basa en nuestra preocupación por la privacidad y la seguridad de los menores en línea, y en la necesidad de cumplir con las regulaciones de privacidad, como la Ley de Protección de la Privacidad en Línea de los Niños (COPPA, por sus siglas en inglés) en los Estados Unidos.
                </p>
                <p className="mb-4">
                    Al establecer una edad mínima para el uso de la aplicación, buscamos proteger a los menores de la recopilación y el uso no autorizados de sus datos personales, así como de posibles contenidos no adecuados para su edad.
                </p>
                <p className="mb-4">
                    Los usuarios menores de 13 años no deben utilizar nuestra aplicación y, en caso de que seamos conscientes de que se ha recopilado información de un menor sin el consentimiento verificable de sus padres o tutores, tomaremos medidas para eliminar esta información de nuestros registros.
                </p>
                <p className="mb-4">
                    La seguridad y la protección de los menores en línea son de suma importancia para nosotros, y al establecer una edad mínima para el uso de la aplicación, buscamos garantizar que los usuarios más jóvenes estén seguros y respaldados por las medidas de privacidad y seguridad apropiadas.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">10. Límite de responsabilidad</h2>
                <p className="mb-4">
                    Los Términos y Condiciones de Deportsen incluyen una sección que limita nuestra responsabilidad en ciertas circunstancias, según lo permitido por la ley. Es importante que los usuarios comprendan estas limitaciones y restricciones. A continuación, se establece esta cláusula de limitación de responsabilidad:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Uso de la aplicación a riesgo del usuario:</strong> Los usuarios deben entender que el uso de la aplicación Deportsen y la participación en actividades de entrenamiento y ejercicio conlleva ciertos riesgos. Los usuarios utilizan la aplicación bajo su propio riesgo y responsabilidad.
                    </li>
                    <li>
                        <strong>Sin garantías explícitas o implícitas:</strong> Deportsen proporciona la aplicación "tal como está" y "según disponibilidad". No hacemos garantías explícitas o implícitas sobre la exactitud, confiabilidad o adecuación de la aplicación para un propósito particular. No garantizamos que la aplicación estará libre de errores o interrupciones.
                    </li>
                    <li>
                        <strong>Limitación de responsabilidad legal:</strong> En la medida en que lo permita la ley aplicable, Deportsen no será responsable de ningún daño directo, indirecto, incidental, especial, consecuente o punitivo, ni de ninguna pérdida de datos, ingresos, oportunidades o beneficios, resultantes del uso de la aplicación o de la imposibilidad de usarla.
                    </li>
                    <li>
                        <strong>Excepciones legales:</strong> Las leyes de algunos países o jurisdicciones pueden no permitir ciertas exclusiones o limitaciones de responsabilidad. En tales casos, nuestras limitaciones y exclusiones de responsabilidad pueden no ser aplicables, y los usuarios pueden tener derechos legales adicionales bajo la legislación local.
                    </li>
                    <li>
                        <strong>Uso razonable:</strong> Los usuarios se comprometen a utilizar la aplicación Deportsen de manera razonable y a cumplir con los Términos y Condiciones establecidos. La limitación de responsabilidad se aplica en la medida en que lo permita la ley y no se extiende a acciones que violen los términos del acuerdo.
                    </li>
                </ul>
                <p className="mb-4">
                    Al utilizar Deportsen, los usuarios reconocen y aceptan estos límites de responsabilidad. Es fundamental comprender que estas limitaciones no buscan eludir las obligaciones legales, sino establecer términos justos y realistas para el uso de la aplicación.
                </p>
                <p className="mb-4">
                    Si los usuarios tienen preguntas adicionales sobre la limitación de responsabilidad o desean obtener más información al respecto, pueden ponerse en contacto con nosotros a través de nuestro correo de soporte: <a href="mailto:support@deportsen.com" className="text-blue-500 underline">support@deportsen.com</a>.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">10. Terminación del Servicio</h2>
                <p className="mb-4">
                    Nos reservamos el derecho de suspender o cancelar el acceso a la aplicación en cualquier momento, sin previo aviso, por cualquier motivo, incluyendo, entre otros, el incumplimiento de los presentes Términos y Condiciones.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">12. Jurisdicción y Ley Aplicable</h2>
                <p className="mb-4">
                    Los Términos y Condiciones de Deportsen están sujetos a la jurisdicción de la República Argentina y se rigen por las leyes de dicho país. La jurisdicción y la ley aplicable son las siguientes:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Jurisdicción:</strong> Los términos y condiciones se rigen por la jurisdicción de la República Argentina.
                    </li>
                    <li>
                        <strong>Ley Aplicable:</strong> Los términos y condiciones se rigen por las leyes de la República Argentina.
                    </li>
                </ul>
                <p className="mb-4">
                    Esta elección de jurisdicción y ley aplicable se realiza de acuerdo con la ubicación del proveedor de servicios, que es de Argentina, y con el objetivo de proporcionar claridad y transparencia en la relación entre Deportsen y los usuarios.
                </p>
                <p className="mb-4">
                    El anonimato y la privacidad de los usuarios se respetan y protegen, y esta información no se divulga en los términos y condiciones. El objetivo es establecer un marco legal justo y transparente para el uso de la aplicación, cumpliendo con las leyes y regulaciones de Argentina.
                </p>
                <p className="mb-4">
                    Si los usuarios tienen preguntas adicionales sobre la jurisdicción y la ley aplicable, pueden ponerse en contacto con nosotros a través de nuestro correo de soporte: <a href="mailto:support@deportsen.com" className="text-blue-500 underline">support@deportsen.com</a>.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">13. Información de contacto</h2>
                <p className="mb-4">
                    Deportsen pone a disposición de los usuarios varios correos electrónicos de contacto y soporte para brindar asistencia y respuesta a preguntas y consultas. A continuación, se proporcionan los correos electrónicos de contacto:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Soporte general:</strong> Para asistencia general, preguntas y consultas, puede ponerse en contacto con nosotros a través del correo electrónico: <a href="mailto:support@deportsen.com" className="text-blue-500 underline">support@deportsen.com</a>.
                    </li>
                    <li>
                        <strong>Contacto principal:</strong> Si desea ponerse en contacto con el equipo principal de Deportsen, puede utilizar el correo electrónico: <a href="mailto:contact@deportsen.com" className="text-blue-500 underline">contact@deportsen.com</a>.
                    </li>
                    <li>
                        <strong>Información y consultas generales:</strong> Para información adicional y consultas generales sobre Deportsen, puede utilizar el correo electrónico: <a href="mailto:info@deportsen.com" className="text-blue-500 underline">info@deportsen.com</a>.
                    </li>
                    <li>
                        <strong>Cuenta:</strong> Para consultas relacionadas con cuentas de usuario, puede ponerse en contacto a través del correo electrónico: <a href="mailto:account@deportsen.com" className="text-blue-500 underline">account@deportsen.com</a>.
                    </li>
                    <li>
                        <strong>Suscripciones y pagos:</strong> Si tiene preguntas específicas sobre suscripciones y pagos, puede utilizar el correo electrónico: <a href="mailto:subscriptions@deportsen.com" className="text-blue-500 underline">subscriptions@deportsen.com</a>.
                    </li>
                </ul>
                <p className="mb-4">
                    Estos correos electrónicos de contacto se proporcionan para que los usuarios puedan comunicarse con Deportsen de manera eficaz y obtener respuestas a sus preguntas y consultas. Nuestro equipo de soporte está disponible para brindar asistencia y resolver inquietudes.
                </p>
                <p className="mb-4">
                    Si los usuarios tienen preguntas o necesitan asistencia, les animamos a ponerse en contacto con nosotros a través del correo electrónico correspondiente para que podamos atender sus necesidades de manera eficiente. Estamos aquí para ayudar y proporcionar la mejor experiencia posible en Deportsen.
                </p>
            </div>
        </div>
    );
};

export default TermsAndConditions;