import React from 'react';
import { TextField, Button, Alert, CircularProgress } from '@mui/material';

const VerificationCodeForm = ({ userEmail, verificationCode, setVerificationCode, handleVerification, isCodeVerified, maxTried, error, isSubmitting, t }) => (
    <form onSubmit={handleVerification} className="mt-4">
        <label className="block text-gray-700 font-medium mb-2">{t('enter_code')}</label>
        <div className="mb-4">
            <TextField
                fullWidth
                label={t('code_verified')}
                variant="outlined"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
            />
        </div>
        <div className='mb-4'>
            {error && <Alert severity="error" className="mb-5">{error}</Alert>}
            {isCodeVerified === false && <Alert severity="error">{t('alert_code_incorrect')}</Alert>}
            {isCodeVerified === true && <Alert severity="success">{t('alert_code_verified')}</Alert>}
            {maxTried === true && <Alert severity="warning">{t('alert_maximum_code')}</Alert>}
        </div>
        <Button type="submit" variant="contained" color="primary" fullWidth disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress size={24} /> : t('verified_code')}
        </Button>
    </form>
);

export default VerificationCodeForm;