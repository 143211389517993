import React from 'react';

const FaqSection = ({ t }) => {
    return (
        <div className="flex justify-center items-center min-h-screen p-4">
            <div className="max-w-4xl bg-white p-8 shadow-md rounded-lg text-left">
                <h1 className="text-3xl font-bold mb-4 text-center">{t('faq_title')}</h1>

                <h2 className="text-xl font-bold mb-2 mt-6 text-center">{t('account_registration')}</h2>
                <h2 className="text-xl font-bold mb-2 mt-6">{t('how_to_register')}</h2>
                <p className="mb-4">{t('how_to_register_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t('use_without_registration')}</h2>
                <p className="mb-4">{t('use_without_registration_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t('required_data')}</h2>
                <p className="mb-4">{t('required_data_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t('forgot_password')}</h2>
                <p className="mb-4">{t('forgot_password_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t('change_username')}</h2>
                <p className="mb-4">{t('change_username_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6 text-center">{t('app_usage')}</h2>
                <h2 className="text-xl font-bold mb-2 mt-6">{t('app_features')}</h2>
                <p className="mb-4">{t('app_features_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t('download_limit')}</h2>
                <p className="mb-4">{t('download_limit_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t('offline_use')}</h2>
                <p className="mb-4">{t('offline_use_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t('remove_favorites')}</h2>
                <p className="mb-4">{t('remove_favorites_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6 text-center">{t('premium_subscription')}</h2>
                <h2 className="text-xl font-bold mb-2 mt-6">{t('subscribe_premium')}</h2>
                <p className="mb-4">{t('subscribe_premium_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t('auto_renew')}</h2>
                <p className="mb-4">{t('auto_renew_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t('cancel_subscription')}</h2>
                <p className="mb-4">{t('cancel_subscription_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t('after_cancellation')}</h2>
                <p className="mb-4">{t('after_cancellation_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6 text-center">{t('privacy_security')}</h2>
                <h2 className="text-xl font-bold mb-2 mt-6">{t('delete_account')}</h2>
                <p className="mb-4">{t('delete_account_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t('security_measures')}</h2>
                <p className="mb-4">{t('security_measures_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6 text-center">{t('intellectual_property')}</h2>
                <h2 className="text-xl font-bold mb-2 mt-6">{t('protect_ip')}</h2>
                <p className="mb-4">{t('protect_ip_answer')}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t('share_content')}</h2>
                <p className="mb-4">{t('share_content_answer')}</p>
            </div>
        </div>
    );
}

export default FaqSection;