import React from 'react';
import parametros from '../parametros/parametros.json';
import { useTranslation } from 'react-i18next';

const FooterWebView = () => {

    const { t } = useTranslation("footer");

    return (
        <footer className="text-white py-10" style={{ backgroundColor: parametros.backGroundColor_azul_oscuro }}>
            <div className="text-center mt-4">
                <p>&copy; {new Date().getFullYear()} {t("text_rights_reserved")}</p>
            </div>
        </footer>
    );
}

export default FooterWebView;