const AboutUs = ({ t }) => {
    return (
        <div className="flex justify-center items-center min-h-screen p-4">
            <div className="max-w-4xl bg-white p-8 shadow-md rounded-lg text-left">
                <h1 className="text-3xl font-bold mb-4 text-center">{t("section_title")}</h1>

                <p className="mb-4 text-center">{t("welcome_message")}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t("passion_title")}</h2>
                <p className="mb-4">{t("passion_content")}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t("mission_vision_title")}</h2>
                <p className="mb-4">{t("mission_content")}</p>
                <p className="mb-4">{t("vision_content")}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t("collaboration_title")}</h2>
                <p className="mb-4">{t("collaboration_content")}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t("quality_title")}</h2>
                <p className="mb-4">{t("quality_content")}</p>

                <h2 className="text-xl font-bold mb-2 mt-6">{t("contact_title")}</h2>
                <p className="mb-4">{t("contact_content")}</p>
                <p className="mb-4">{t("thanks")} </p>
            </div>
        </div>
    )
}

export default AboutUs;