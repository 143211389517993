import React from 'react';
import { TextField, MenuItem } from '@mui/material';

const DeleteAccountReason = ({ reason, setReason, otherReason, setOtherReason, t }) => (
    <div className="mb-4">
        <TextField
            fullWidth
            select
            label={t('reason_for_deletion')}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
        >
            <MenuItem value={t('no_app_use')}>{t('no_app_use')}</MenuItem>
            <MenuItem value={t('better_alternative')}>{t('better_alternative')}</MenuItem>
            <MenuItem value={t('technical_problems')}>{t('technical_problems')}</MenuItem>
            <MenuItem value={t('data_privacy')}>{t('data_privacy')}</MenuItem>
            <MenuItem value="other">{t('other_specify')}</MenuItem>
        </TextField>
        {reason === 'other' && (
            <div className="mt-4">
                <label className="block text-gray-700 font-medium mb-2">{t('other_specify_2')}</label>
                <TextField
                    fullWidth
                    multiline
                    variant="outlined"
                    rows={3}
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                    className="mt-2"
                    required
                />
            </div>
        )}
    </div>
);

export default DeleteAccountReason;
