import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
};

const ProductItem = () => {

    const { t } = useTranslation("home");

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <div className="p-0">
            <motion.div
                className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                style={{ backgroundColor: "#fff" }}
                ref={ref}
                inView={inView}
            >
                <div className="flex flex-col md:flex-row items-center md:flex-row py-8 w-[70%] m-auto">
                    <img src={"https://firebasestorage.googleapis.com/v0/b/deportsenapp.appspot.com/o/web%2FpaginaMuestraApp%2FcapturasApp%2Fentreno.png?alt=media&token=4011cf01-775c-4744-9619-0f6da581689c"} alt={t("section_1_title")} className="w-[60vw] h-auto md:w-[20vw]" />
                    <div className="md:mx-8 text-center md:text-left mt-10">
                        <h2 className="text-2xl font-bold mb-4">{t("section_1_title")}</h2>
                        <p>{t("section_1_description")}</p>
                    </div>
                </div>
            </motion.div>

            <motion.div
                className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                style={{ backgroundColor: "#33333313" }}
                ref={ref}
                inView={inView}
            >
                <div className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8 w-[70%] m-auto">
                    <img src={"https://firebasestorage.googleapis.com/v0/b/deportsenapp.appspot.com/o/web%2FpaginaMuestraApp%2FcapturasApp%2Fentreno-detalle.png?alt=media&token=814de4a6-f132-4d00-aa06-ad18448c1bba"} alt={t("section_2_title")} className="w-[60vw] h-auto md:w-[20vw]" />
                    <div className="md:mx-8 text-center md:text-left mt-10">
                        <h2 className="text-2xl font-bold mb-4">{t("section_2_title")}</h2>
                        <p>{t("section_2_description")}</p>
                    </div>
                </div>
            </motion.div>

            <motion.div
                className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                style={{ backgroundColor: "#fff" }}
                ref={ref}
                inView={inView}
            >
                <div className="flex flex-col md:flex-row items-center md:flex-row py-8 w-[70%] m-auto">
                    <img src={"https://firebasestorage.googleapis.com/v0/b/deportsenapp.appspot.com/o/web%2FpaginaMuestraApp%2FcapturasApp%2Ffavoritos.png?alt=media&token=7bf5c58b-9c5a-4451-8a9b-4f955770073e"} alt={t("section_3_title")} className="w-[60vw] h-auto md:w-[20vw]" />
                    <div className="md:mx-8 text-center md:text-left mt-10">
                        <h2 className="text-2xl font-bold mb-4">{t("section_3_title")}</h2>
                        <p>{t("section_3_description")}</p>
                    </div>
                </div>
            </motion.div>

            <motion.div
                className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                style={{ backgroundColor: "#33333313" }}
                ref={ref}
                inView={inView}
            >
                <div className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8 w-[70%] m-auto">
                    <img src={"https://firebasestorage.googleapis.com/v0/b/deportsenapp.appspot.com/o/web%2FpaginaMuestraApp%2FcapturasApp%2Fprotips-motivacion.png?alt=media&token=1ba05e42-dfe5-429a-81ee-0445d729b21c"} alt={t("section_4_title")} className="w-[60vw] h-auto md:w-[20vw]" />
                    <div className="md:mx-8 text-center md:text-left mt-10">
                        <h2 className="text-2xl font-bold mb-4">{t("section_4_title")}</h2>
                        <p>{t("section_4_description")}</p>
                    </div>
                </div>
            </motion.div>

            <motion.div
                className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                style={{ backgroundColor: "#fff" }}
                ref={ref}
                inView={inView}
            >
                <div className="flex flex-col md:flex-row items-center md:flex-row py-8 w-[70%] m-auto">
                    <img src={"https://firebasestorage.googleapis.com/v0/b/deportsenapp.appspot.com/o/web%2FpaginaMuestraApp%2FcapturasApp%2Fplanes.png?alt=media&token=383ced44-d113-4f08-bd2d-1bf71a2cdb3c"} alt={t("section_5_title")} className="w-[60vw] h-auto md:w-[20vw]" />
                    <div className="md:mx-8 text-center md:text-left mt-10">
                        <h2 className="text-2xl font-bold mb-4">{t("section_5_title")}</h2>
                        <p>{t("section_5_description")}</p>
                    </div>
                </div>
            </motion.div>

            <motion.div
                className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                style={{ backgroundColor: "#33333313" }}
                ref={ref}
                inView={inView}
            >
                <div className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8 w-[70%] m-auto">
                    <img src={"https://firebasestorage.googleapis.com/v0/b/deportsenapp.appspot.com/o/web%2FpaginaMuestraApp%2FcapturasApp%2Fcarta-jugador.png?alt=media&token=fea694dc-eb7c-4ef4-a3cd-6d9d7f5b956f"} alt={t("section_5_title")} className="w-[60vw] h-auto md:w-[20vw]" />
                    <div className="md:mx-8 text-center md:text-left mt-10">
                        <h2 className="text-2xl font-bold mb-4">{t("section_7_title")}</h2>
                        <p>{t("section_7_description")}</p>
                    </div>
                </div>
            </motion.div>

            <motion.div
                className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                style={{ backgroundColor: "#fff" }}
                ref={ref}
                inView={inView}
            >
                <div className="flex flex-col md:flex-row items-center md:flex-row py-8 w-[70%] m-auto">
                    <img src={"https://firebasestorage.googleapis.com/v0/b/deportsenapp.appspot.com/o/web%2FpaginaMuestraApp%2FcapturasApp%2Fcalendario.png?alt=media&token=59f334a7-9a63-46ce-9dbe-d40f2fd9f236"} alt={t("section_6_title")} className="w-[60vw] h-auto md:w-[20vw]" />
                    <div className="md:mx-8 text-center md:text-left mt-10">
                        <h2 className="text-2xl font-bold mb-4">{t("section_8_title")}</h2>
                        <p>{t("section_8_description")}</p>
                    </div>
                </div>
            </motion.div>

            <motion.div
                className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                style={{ backgroundColor: "#33333313" }}
                ref={ref}
                inView={inView}
            >
                <div className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8 w-[70%] m-auto">
                    <img src={"https://firebasestorage.googleapis.com/v0/b/deportsenapp.appspot.com/o/web%2FpaginaMuestraApp%2FcapturasApp%2Fidiomas.png?alt=media&token=90df5e45-c319-44da-bfd1-3c0532262fc6"} alt={t("section_6_title")} className="w-[60vw] h-auto md:w-[20vw]" />
                    <div className="md:mx-8 text-center md:text-left mt-10">
                        <h2 className="text-2xl font-bold mb-4">{t("section_6_title")}</h2>
                        <p>{t("section_6_description")}</p>
                    </div>
                </div>
            </motion.div>
        </div>
    );
}

export default ProductItem;