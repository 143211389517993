import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ScrollToTop from './components/utils/ScrollTotop';
import Header from './components/header/Header';
import HeaderWebView from './components/webViewApp/HeaderWebView';
import PhoneSection from './components/items/PhoneSection';
import HeaderSection from './components/items/HeaderSection';
import ProductItem from './components/items/ProductItem';
import FooterSection from './components/items/FooterSection';
import Footer from './components/footer/Footer';
import FooterWebView from './components/webViewApp/FooterWebView';
import TermsAndConditions from './components/pages/legal/termsAndConditions/termsAndConditions';
import PrivacyPolicies from './components/pages/legal/privacyPolicies/privacyPolicies';
import AboutUs from './components/pages/aboutUs/aboutUs';
import FaqSection from './components/pages/faq/FaqSection';
import DeleteAccountForm from './components/pages/deleteAccount/DeleteAccountForm';
import RedirectToHome from './components/utils/redirectToHome';
import './components/firebase/firebaseConfig';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route path="/" element={<RedirectToHome />} />
          <Route path="/home/:lng" element={<Home />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/privacy-policies" element={<Privacy />} />
          <Route path="/about-us/:lng" element={<About />} />
          <Route path="/faq/:lng" element={<Faq />} />
          <Route path="/delete-account/:lng" element={<DeleteUserForm />} />
          <Route path="/web-view/faq/:lng" element={<FaqWebView />} />
          <Route path="/web-view/delete-account/:lng" element={<DeleteAccountWebView />} />
        </Routes>
      </div>
    </Router>
  );
}

const Home = () => {

  const { lng } = useParams();
  const { i18n, t } = useTranslation("home");

  useEffect(() => {
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n]);

  const [loading, setLoading] = useState(false);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <PhoneSection setLoading={setLoading} t={t} />
      {loading && (
        <>
          <HeaderSection t={t} />
          <ProductItem t={t} />
          <FooterSection t={t} />
          <Footer />
        </>)}
    </div>
  );
}

const Terms = () => {

  // eslint-disable-next-line
  const { t } = useTranslation("home");

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow my-20 lg:mx-20 md:mx-8 sm:mx-4 mx-4">
        <TermsAndConditions />
      </main>
      <Footer />
    </div>
  );
}

const Privacy = () => {

  // eslint-disable-next-line
  const { t } = useTranslation("home");

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow my-20 lg:mx-20 md:mx-8 sm:mx-4 mx-4">
        <PrivacyPolicies />
      </main>
      <Footer />
    </div>
  );
}

const About = () => {

  const { lng } = useParams();
  const { i18n, t } = useTranslation("about");

  useEffect(() => {
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow my-20 lg:mx-20 md:mx-8 sm:mx-4 mx-4">
        <AboutUs t={t} />
      </main>
      <Footer />
    </div>
  );
}

const Faq = () => {

  const { lng } = useParams();
  const { i18n, t } = useTranslation("faq");

  useEffect(() => {
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow my-20 lg:mx-20 md:mx-8 sm:mx-4 mx-4">
        <FaqSection t={t} />
      </main>
      <Footer />
    </div>
  );
}

const DeleteUserForm = () => {

  const { lng } = useParams();
  const { i18n, t } = useTranslation("deleteAccount");

  useEffect(() => {
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow my-20 lg:mx-20 md:mx-8 sm:mx-4 mx-4">
        <DeleteAccountForm t={t} />
      </main>
      <Footer />
    </div>
  );
}

const FaqWebView = () => {
  const { lng } = useParams();
  const { i18n, t } = useTranslation("faq");

  useEffect(() => {
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n]);

  return (
    <div className="flex flex-col min-h-screen">
      <HeaderWebView />
      <main className="flex-grow my-20 lg:mx-20 md:mx-8 sm:mx-4 mx-4">
        <FaqSection t={t} />
      </main>
      <FooterWebView />
    </div>
  );
}

const DeleteAccountWebView = () => {
  const { lng } = useParams();
  const { i18n, t } = useTranslation("deleteAccount");

  useEffect(() => {
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n]);

  return (
    <div className="flex flex-col min-h-screen">
      <HeaderWebView />
      <main className="flex-grow my-20 lg:mx-20 md:mx-8 sm:mx-4 mx-4">
        <DeleteAccountForm t={t} />
      </main>
      <FooterWebView />
    </div>
  );
}

export default App;
