const PrivacyPolicies = () => {
    return (
        <div className="flex justify-center items-center min-h-screen p-4">
            <div className="max-w-4xl bg-white p-8 shadow-md rounded-lg text-left">
                <h1 className="text-3xl font-bold mb-4 text-center">Políticas de Privacidad Deportsen</h1>
                <p className="mb-4 text-center">Fecha de entrada en vigor: 01/02/2024</p>

                <p className="mb-4">
                    En Deportsen, valoramos la privacidad y la seguridad de nuestros usuarios. Estamos comprometidos a proteger sus datos personales y ofrecer una experiencia segura y confiable en nuestra aplicación. Antes de comenzar a utilizar Deportsen, le invitamos a revisar detenidamente nuestras Políticas de Privacidad.
                </p>
                <p className="mb-4">
                    Estas políticas de privacidad detallan cómo recopilamos, utilizamos y protegemos sus datos personales, así como sus derechos y opciones con respecto a la información que compartimos con nosotros. Entendemos la importancia de que esté informado acerca de cómo manejamos sus datos, y estamos comprometidos a mantener la privacidad y seguridad de su información personal.
                </p>
                <p className="mb-4">
                    El uso de nuestra aplicación implica la aceptación de nuestras políticas y términos, por lo que le recomendamos leer este documento con atención. Si decide utilizar Deportsen, acepta plenamente nuestras políticas y términos.
                </p>
                <p className="mb-4">
                    Si tiene alguna pregunta o inquietud con respecto a nuestros políticas de privacidad, no dude en ponerse en contacto con nuestro equipo de soporte a través de <a href="mailto:support@deportsen.com" className="text-blue-500 underline">support@deportsen.com</a>. Estamos aquí para ayudar y proporcionar la información que necesite para disfrutar de una experiencia segura y satisfactoria en Deportsen.
                </p>
                <p className="mb-4">
                    Gracias por unirse a Deportsen y por confiar en nosotros para su entrenamiento. Esperamos que disfrute de nuestra aplicación y que alcance sus metas de entrenamiento de fútbol de manera efectiva y divertida.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">1. Modificaciones</h2>
                <p className="mb-4">
                    Nos reservamos el derecho de modificar estas Políticas de Privacidad en cualquier momento. Te recomendamos revisar esta página regularmente para estar al tanto de los cambios. El uso continuado de la aplicación después de los cambios constituye tu aceptación de las Políticas de Privacidad.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">2. Recopilación de datos personales</h2>
                <p className="mb-4">
                    En Deportsen, recopilamos una serie de datos personales con el fin de brindar a nuestros usuarios una experiencia personalizada y eficaz. Los tipos de datos personales que recopilamos incluyen, pero no se limitan a:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Nombre:</strong> Para personalizar la experiencia del usuario y dirigirnos a los usuarios de manera adecuada.
                    </li>
                    <li>
                        <strong>Correo Electrónico:</strong> Para establecer una vía de comunicación y notificar a los usuarios sobre aspectos relevantes de la aplicación, como cambios en los términos y condiciones, notificaciones de suscripción, actualizaciones importantes, y la gestión de su cuenta.
                    </li>
                    <li>
                        <strong>Foto de perfil:</strong> Para permitir a los usuarios personalizar su perfil y mostrar una imagen identificativa dentro de la aplicación.
                    </li>
                </ul>
                <p className="mb-4">
                    La creación de una cuenta y la provisión de esta información son requisitos necesarios para acceder a la aplicación. Respetamos la privacidad y elección de los usuarios en todo momento y garantizamos que cualquier dato recopilado se utilizará de acuerdo con las disposiciones de esta Política de Privacidad.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">3. Uso de datos personales</h2>
                <p className="mb-4">
                    En Deportsen, nos comprometemos a utilizar los datos personales recopilados de manera responsable y con el objetivo de mejorar la experiencia del usuario, brindar servicios personalizados y garantizar el funcionamiento efectivo de la aplicación. A continuación, se describen los propósitos principales de la recopilación de datos personales:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Personalización de la experiencia del usuario:</strong> Los datos personales, como el nombre y la foto de perfil, se utilizan para crear un entorno más personalizado dentro de la aplicación. Esto permite dirigirnos a los usuarios de manera adecuada y proporcionar una experiencia única y relevante.
                    </li>
                    <li>
                        <strong>Comunicación con el usuario:</strong> El correo electrónico se utiliza como medio de comunicación con los usuarios. A través del correo electrónico, notificamos a los usuarios sobre asuntos importantes, como cambios en los términos y condiciones, actualizaciones de la aplicación, notificaciones de suscripción y eventos relacionados con sus cuentas.
                    </li>
                    <li>
                        <strong>Gestión de cuentas de usuario:</strong> Los datos personales facilitan la identificación de los usuarios y la gestión de sus cuentas. Esto incluye la recuperación de contraseñas, la verificación de identidad y la asistencia con problemas de acceso a la aplicación.
                    </li>
                    <li>
                        <strong>Servicios personalizados:</strong> Utilizamos la información recopilada para brindar a los usuarios recomendaciones de contenido y servicios personalizados. Esto incluye sugerencias de ejercicios, planes de entrenamiento y protips que se ajusten a los intereses y preferencias del usuario.
                    </li>
                    <li>
                        <strong>Procesamiento de pagos:</strong> En caso de que un usuario adquiera una suscripción Premium a través de Google Play, requerimos ciertos datos para facilitar el proceso de pago. Estos datos se utilizan exclusivamente con fines de facturación y garantizan que los usuarios tengan acceso a los beneficios de la suscripción Premium.
                    </li>
                    <li>
                        <strong>Mejora continua de la aplicación:</strong> Los datos agregados y anónimos se utilizan para analizar el rendimiento de la aplicación, identificar áreas de mejora y tomar decisiones informadas para optimizar la experiencia del usuario.
                    </li>
                </ul>
                <p className="mb-4">
                    Es fundamental destacar que el uso de datos personales se limita a los propósitos descritos anteriormente. Deportsen no comparte, vende ni alquila los datos personales de los usuarios a terceros sin su consentimiento expreso. Estamos comprometidos con la privacidad y seguridad de la información de nuestros usuarios y cumplimos con las leyes y regulaciones aplicables para garantizar la protección de los datos personales. Los usuarios tienen el control sobre su información y pueden ajustar sus preferencias de privacidad en la aplicación en cualquier momento.
                </p>
                <h2 className="text-xl font-bold mb-2 mt-6">4. Compartir datos con terceros</h2>
                <p className="mb-4">
                    En Deportsen, mantenemos un compromiso sólido con la privacidad y la protección de los datos personales de nuestros usuarios. En general, no compartimos datos personales con terceros, a menos que sea necesario para el funcionamiento de la aplicación o estemos legalmente obligados a hacerlo. A continuación, se detallan las situaciones en las que se podrían compartir datos con terceros:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Suscripciones premium a través de Google Play:</strong> En el caso de que un usuario adquiera una suscripción Premium a través de Google Play, Google Play Store procesará la transacción y gestionará los datos necesarios para efectuar el pago. Google Play estará sujeto a sus propias políticas de privacidad y términos de servicio. Recomendamos que los usuarios revisen estas políticas al adquirir una suscripción Premium a través de Google Play.
                    </li>
                    <li>
                        <strong>Proveedores de servicios:</strong> En algunas circunstancias, podríamos compartir datos personales con proveedores de servicios de confianza que nos asisten en la gestión y el mantenimiento de la aplicación. Estos proveedores de servicios están sujetos a acuerdos de confidencialidad y solo tienen acceso a los datos necesarios para realizar las tareas específicas requeridas.
                    </li>
                    <li>
                        <strong>Cumplimiento legal y seguridad:</strong> Podríamos divulgar datos personales si estamos legalmente obligados a hacerlo en respuesta a una solicitud de una autoridad competente o en caso de que consideremos que es necesario para proteger la seguridad, los derechos o la propiedad de Deportsen, de nuestros usuarios o de terceros.
                    </li>
                </ul>
                <p className="mb-4">
                    Es importante subrayar que, en ningún momento compartimos datos personales con anunciantes sin el consentimiento expreso de los usuarios. Además, cualquier compartición de datos con terceros se lleva a cabo en estricto cumplimiento con las leyes y regulaciones aplicables en materia de privacidad.
                </p>
                <p className="mb-4">
                    Reiteramos nuestro compromiso con la protección de la privacidad de los usuarios y la transparencia en el manejo de datos personales. Para obtener información detallada sobre cómo se gestionan los datos en situaciones específicas, recomendamos a los usuarios consultar las políticas de privacidad de terceros relevantes, como Google Play Store.
                </p>

                <h2 className="text-xl font-bold mb-2 mt-6">5. Publicidad en la aplicación</h2>
                <p className="mb-4">
                    En Deportsen, deseamos informar a nuestros usuarios sobre la presencia de anuncios publicitarios en la aplicación. Los anuncios son una parte importante de nuestra plataforma, ya que contribuyen a mantener la aplicación gratuita y accesible para todos los usuarios. Aquí proporcionamos detalles sobre cómo funcionan los anuncios en Deportsen:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Anuncios visualizados:</strong> Durante la utilización de la aplicación, los usuarios pueden encontrar anuncios en forma de videos publicitarios y banners publicitarios en varias ubicaciones. Estos anuncios son proporcionados por terceros anunciantes y redes de publicidad.
                    </li>
                    <li>
                        <strong>Relevancia de los anuncios:</strong> Nos esforzamos por mostrar anuncios que sean relevantes para los intereses y preferencias de los usuarios siempre que sea posible. Esto se logra utilizando información anónima y agregada, como las preferencias del usuario y el comportamiento de navegación.
                    </li>
                    <li>
                        <strong>Control de anuncios:</strong> Entendemos que la experiencia del usuario es primordial. Por lo tanto, brindamos a los usuarios la opción de cerrar anuncios o interactuar con ellos según su preferencia. Además, respetamos la configuración de las preferencias de publicidad de los dispositivos móviles de los usuarios, si están disponibles.
                    </li>
                    <li>
                        <strong>Recopilación de datos para publicidad:</strong> La visualización de anuncios publicitarios puede implicar la recopilación de ciertos datos técnicos, como la dirección IP, el identificador publicitario del dispositivo y la ubicación aproximada del usuario. Estos datos son utilizados por terceros anunciantes para mejorar la relevancia de los anuncios.
                    </li>
                </ul>
                <p className="mb-4">
                    Es importante señalar que los datos recopilados para fines publicitarios se utilizan de manera agregada y anónima para proteger la privacidad del usuario. No compartimos datos personales con anunciantes sin el consentimiento expreso del usuario.
                </p>
                <p className="mb-4">
                    Los usuarios que elijan actualizar a una suscripción Premium podrán disfrutar de una experiencia sin anuncios en la aplicación. Esta suscripción elimina la visualización de anuncios publicitarios para garantizar una experiencia ininterrumpida y sin distracciones.
                </p>
                <p className="mb-4">
                    En Deportsen, estamos comprometidos a equilibrar la presentación de anuncios con una experiencia de usuario de alta calidad y la protección de la privacidad de los datos personales de nuestros usuarios. Si los usuarios tienen preguntas adicionales o desean obtener más información sobre nuestra política de publicidad, les animamos a ponerse en contacto con nosotros a través de nuestro correo de soporte: <a href="mailto:support@deportsen.com" className="text-blue-500 underline">support@deportsen.com</a>.
                </p>

                <h2 className="text-xl font-bold mb-2 mt-6">6. Cookies y seguimiento</h2>
                <p className="mb-4">
                    En Deportsen, utilizamos cookies y otras tecnologías de seguimiento con el objetivo de mejorar la funcionalidad y la experiencia del usuario en nuestra aplicación. A continuación, proporcionamos información detallada sobre el uso de estas tecnologías:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Cookies:</strong> Las cookies son pequeños archivos de texto que se almacenan en el dispositivo del usuario cuando accede a nuestra aplicación. Estas cookies pueden contener información que permite el correcto funcionamiento de la aplicación, como la autenticación del usuario y la personalización de la experiencia.
                    </li>
                    <li>
                        <strong>Otras tecnologías de seguimiento:</strong> Además de las cookies, también podemos utilizar otras tecnologías de seguimiento, como píxeles de seguimiento y almacenamiento local. Estas tecnologías se utilizan para recopilar información sobre la interacción del usuario con la aplicación y mejorar la funcionalidad.
                    </li>
                    <li>
                        <strong>Propósitos de las cookies y tecnologías de seguimiento: Las cookies y tecnologías de seguimiento se utilizan para diversos propósitos, que incluyen:</strong>
                        <ul>
                            <li>Autenticación y seguridad de la cuenta.</li>
                            <li>Personalización de la experiencia del usuario.</li>
                            <li>Análisis del rendimiento de la aplicación.</li>
                            <li>Seguimiento del comportamiento del usuario dentro de la aplicación.</li>
                            <li>Publicidad y análisis de publicidad.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Control de cookies y tecnologías de seguimiento:</strong> Los usuarios tienen el control sobre el uso de cookies y tecnologías de seguimiento a través de la configuración de sus dispositivos y la configuración de privacidad de la aplicación. Pueden optar por aceptar o rechazar el uso de cookies y tecnologías de seguimiento según sus preferencias.
                    </li>
                    <li>
                        <strong>Cookies de terceros:</strong> En algunos casos, las cookies de terceros pueden utilizarse para fines como publicidad y análisis. Los usuarios pueden gestionar la configuración de cookies de terceros a través de las opciones proporcionadas por estos terceros o en la configuración de privacidad de la aplicación.
                    </li>
                </ul>
                <p className="mb-4">
                    Es importante destacar que las cookies y tecnologías de seguimiento se utilizan de manera responsable y se mantienen en cumplimiento con las leyes y regulaciones aplicables en materia de privacidad. Los datos recopilados a través de estas tecnologías se utilizan para mejorar la experiencia del usuario y no se comparten con terceros sin el consentimiento expreso del usuario.
                </p>
                <p className="mb-4">
                    Para obtener más información sobre las medidas de seguridad específicas en Deportsen o para informar problemas de seguridad, los usuarios pueden ponerse en contacto con nosotros a través de nuestro correo de soporte: <a href="mailto:support@deportsen.com" className="text-blue-500 underline">support@deportsen.com</a>.
                </p>

                <h2 className="text-xl font-bold mb-2 mt-6">7. Protección de datos y seguridad</h2>
                <p className="mb-4">
                    En Deportsen, la protección de los datos de nuestros usuarios es una prioridad fundamental. Implementamos una serie de medidas de seguridad sólidas para garantizar que la información de los usuarios esté resguardada de manera efectiva. A continuación, se describen las medidas de seguridad clave que hemos implementado:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Cifrado de datos:</strong> Utilizamos tecnologías de cifrado sólidas para proteger la transmisión y el almacenamiento de datos sensibles. Esto incluye la utilización de protocolos de cifrado seguros para la comunicación entre la aplicación y nuestros servidores.
                    </li>
                    <li>
                        <strong>Autenticación segura:</strong> Implementamos procedimientos de autenticación sólidos para asegurar que solo los usuarios autorizados tengan acceso a sus cuentas. Esto incluye la protección de las credenciales de inicio de sesión y la verificación en dos pasos opcional.
                    </li>
                    <li>
                        <strong>Protección de la infraestructura:</strong> Mantenemos nuestra infraestructura segura y actualizada con las últimas medidas de seguridad. Realizamos auditorías regulares y pruebas de seguridad para identificar y abordar posibles vulnerabilidades.
                    </li>
                    <li>
                        <strong>Gestión de acceso:</strong> Controlamos el acceso a los datos de los usuarios y garantizamos que solo el personal autorizado tenga acceso a información confidencial. Además, garantizamos que se cumplan los principios de mínimos privilegios.
                    </li>
                    <li>
                        <strong>Capacitación en seguridad:</strong> Nuestro equipo recibe capacitación en seguridad de datos para comprender y abordar posibles amenazas y vulnerabilidades. Fomentamos una cultura de seguridad en toda la organización.
                    </li>
                    <li>
                        <strong>Respuesta a incidentes:</strong> Tenemos un plan de respuesta a incidentes en marcha para abordar posibles brechas de seguridad. Esto incluye la notificación oportuna a las partes afectadas y a las autoridades competentes en caso de una violación de datos.
                    </li>
                    <li>
                        <strong>Seguridad de datos en tránsito y en reposo:</strong> Protegemos los datos en tránsito mediante conexiones cifradas y aplicamos medidas de seguridad sólidas para garantizar la protección de los datos almacenados en nuestros servidores.
                    </li>
                    <li>
                        <strong>Evaluación de riesgos:</strong> Regularmente evaluamos los riesgos para la seguridad de los datos y aplicamos medidas preventivas para reducir estos riesgos.
                    </li>
                    <li>
                        <strong>Cumplimiento normativo:</strong> Nos comprometemos a cumplir con todas las leyes y regulaciones de privacidad de datos aplicables y estamos comprometidos con la privacidad y la confidencialidad de los datos de nuestros usuarios.
                    </li>
                    <li>
                        <strong>Copias de seguridad:</strong> Realizamos copias de seguridad regulares de los datos para garantizar la disponibilidad y recuperación de datos en caso de un fallo del sistema o pérdida de datos.
                    </li>
                </ul>
                <p className="mb-4">
                    Es importante destacar que la seguridad de los datos es un esfuerzo continuo y que estamos dedicados a mejorar nuestras medidas de seguridad en respuesta a las amenazas emergentes. Además, fomentamos la colaboración activa de nuestros usuarios al notificar cualquier actividad sospechosa o problemas de seguridad que puedan encontrar.
                </p>

                <h2 className="text-xl font-bold mb-2 mt-6">8. Edad mínima para el uso de la aplicación</h2>
                <p className="mb-4">
                    En Deportsen, establecemos una edad mínima para el uso de nuestra aplicación. Los usuarios deben tener al menos 13 años de edad para poder registrarse y utilizar nuestra plataforma. Esta restricción se aplica de acuerdo con nuestra política y cumple con las regulaciones de privacidad de datos y protección de menores.
                </p>
                <p className="mb-4">
                    La decisión de establecer esta edad mínima se basa en nuestra preocupación por la privacidad y la seguridad de los menores en línea, y en la necesidad de cumplir con las regulaciones de privacidad, como la Ley de Protección de la Privacidad en Línea de los Niños (COPPA, por sus siglas en inglés) en los Estados Unidos.
                </p>
                <p className="mb-4">
                    Al establecer una edad mínima para el uso de la aplicación, buscamos proteger a los menores de la recopilación y el uso no autorizados de sus datos personales, así como de posibles contenidos no adecuados para su edad.
                </p>
                <p className="mb-4">
                    Los usuarios menores de 13 años no deben utilizar nuestra aplicación y, en caso de que seamos conscientes de que se ha recopilado información de un menor sin el consentimiento verificable de sus padres o tutores, tomaremos medidas para eliminar esta información de nuestros registros.
                </p>
                <p className="mb-4">
                    La seguridad y la protección de los menores en línea son de suma importancia para nosotros, y al establecer una edad mínima para el uso de la aplicación, buscamos garantizar que los usuarios más jóvenes estén seguros y respaldados por las medidas de privacidad y seguridad apropiadas.
                </p>

                <h2 className="text-xl font-bold mb-2 mt-6">9. Idiomas disponibles y Política de Privacidad</h2>
                <p className="mb-4">
                    En Deportsen, estamos comprometidos a hacer que nuestra aplicación sea accesible para una audiencia global. Por lo tanto, ofrecemos nuestra aplicación en varios idiomas para satisfacer las necesidades de usuarios de diferentes partes del mundo. Los idiomas disponibles en Deportsen incluyen, pero no se limitan a, español, inglés, portugués, turco, francés, italiano, ruso, ucraniano, alemán e hindi.
                </p>
                <p className="mb-4">
                    La disponibilidad de múltiples idiomas no afecta sustancialmente a nuestra política de privacidad. La Política de Privacidad de Deportsen se mantiene consistente en todos los idiomas en los que está disponible la aplicación. Esto significa que las normas y prácticas de privacidad son las mismas para todos los usuarios, independientemente del idioma que elijan.
                </p>
                <p className="mb-4">
                    Es importante destacar que todas las versiones de nuestra Política de Privacidad están diseñadas para cumplir con las leyes y regulaciones de privacidad aplicables en las jurisdicciones relevantes. Independientemente del idioma en el que se presente la política, garantizamos que cumple con los estándares de privacidad y protección de datos que son pertinentes en cada región.
                </p>
                <p className="mb-4">
                    Los usuarios pueden seleccionar su idioma preferido en la aplicación, lo que afectará la presentación de la interfaz de usuario y el contenido, pero no modificará sustancialmente los principios y las disposiciones de la Política de Privacidad.
                </p>
                <p className="mb-4">
                    Nuestra prioridad es mantener una experiencia de usuario consistente y segura en todos los idiomas, asegurando que todos los usuarios reciban el mismo nivel de protección de privacidad, sin importar el idioma en el que utilicen la aplicación.
                </p>

                <h2 className="text-xl font-bold mb-2 mt-6">10. Servicios de terceros</h2>
                <p className="mb-4">
                    Utilizamos servicios de terceros, Firebase y Google Cloud, para proporcionar autenticación y almacenamiento de datos en Deportsen. Firebase es una plataforma de desarrollo de aplicaciones móviles y web proporcionada por Google LLC. Google Cloud es una plataforma de computación en la nube y servicios relacionados proporcionada por Google Cloud Platform.
                </p>
                <p className="mb-4">
                    Al utilizar Deportsen, debe tener en cuenta lo siguiente:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Autenticación con Firebase:</strong> Utilizamos Firebase Authentication para gestionar los procesos de inicio de sesión y registro. Los datos de autenticación que proporciona se almacenan de forma segura y se utilizan para permitirle acceder y utilizar nuestra aplicación.
                    </li>
                    <li>
                        <strong>Almacenamiento en Google Cloud:</strong> Las imágenes y videos que carga en Deportsen se almacenan en Google Cloud Storage, un servicio de Google Cloud. Los datos se almacenan de manera segura y se utilizan para proporcionar contenido multimedia a través de nuestra aplicación.
                    </li>
                    <li>
                        <strong>Protección de datos:</strong> Tanto Firebase como Google Cloud implementan medidas de seguridad para proteger sus datos personales. Los datos se almacenan en servidores seguros y se gestionan de acuerdo con las prácticas recomendadas de seguridad de datos.
                    </li>
                    <li>
                        <strong>Eliminación de datos:</strong> Los datos almacenados en Firebase y Google Cloud se conservan durante el tiempo necesario para proporcionar nuestros servicios. Si decide eliminar su cuenta, sus datos se eliminarán de manera segura de estos servicios.
                    </li>
                </ul>
                <p className="mb-4">
                    Para obtener más información sobre la protección de datos y políticas de privacidad de Firebase y Google Cloud, le recomendamos consultar las políticas de privacidad de Google LLC. Puede encontrar más información en los siguientes enlaces:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <a href="https://firebase.google.com/support/privacy?hl=es-419" className="text-blue-500 underline">Política de Privacidad de Firebase</a>
                    </li>
                    <li>
                        <a href="https://policies.google.com/privacy" className="text-blue-500 underline">Política de Privacidad de Google</a>
                    </li>
                </ul>
                <p className="mb-4">
                    El uso de Deportsen implica la aceptación y comprensión de nuestra relación con estos servicios de terceros y sus políticas de privacidad. Asegúrese de revisar sus políticas para comprender completamente cómo se gestionan sus datos en nuestra aplicación.
                </p>

                <h2 className="text-xl font-bold mb-2 mt-6">11. Eliminación de cuenta y proceso</h2>
                <p className="mb-4">
                    En Deportsen, comprendemos que los usuarios pueden optar por eliminar sus cuentas por diversas razones, y respetamos su derecho a hacerlo. A continuación, explicamos el proceso de eliminación de cuenta y el tiempo que puede demorar:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Inicio del proceso de eliminación:</strong> Cuando un usuario decide eliminar su cuenta en Deportsen, el proceso comienza llenando un formulario de eliminación de cuenta. Este formulario se puede acceder desde la página oficial de Deportsen en deportsen.com o desde la aplicación.
                    </li>
                    <li>
                        <strong>Confirmación por Correo Electrónico:</strong> Una vez que el usuario ha completado el formulario de eliminación de cuenta, se le enviará un correo electrónico de confirmación a la dirección de correo electrónico asociada a la cuenta. Este correo electrónico contendrá un código de verificación que el usuario debe ingresar para confirmar su intención de eliminar la cuenta.
                    </li>
                    <li>
                        <strong>Proceso de eliminación:</strong> Después de ingresar el código de verificación, se confirma la solicitud de eliminación de la cuenta. A partir de este momento, el proceso de eliminación se pone en marcha.
                    </li>
                    <li>
                        <strong>Tiempo de eliminación:</strong> El tiempo necesario para completar el proceso de eliminación de cuenta puede variar y puede tomar entre 15 y 30 días. Durante este período, se llevarán a cabo diversas acciones, como la verificación de la autenticidad de la solicitud y la eliminación segura de los datos de la cuenta.
                    </li>
                    <li>
                        <strong>Notificación al usuario:</strong> Durante el proceso de eliminación, se puede notificar al usuario por correo electrónico sobre el progreso del proceso. Esto puede incluir confirmaciones intermedias y notificaciones finales cuando se haya completado la eliminación.
                    </li>
                </ul>
                <p className="mb-4">
                    Es importante tener en cuenta que el tiempo de eliminación puede variar según diversos factores, como la carga de trabajo del equipo de soporte y el proceso de verificación de la solicitud. Nuestro objetivo es completar el proceso de eliminación de cuenta de manera segura y efectiva, garantizando que se eliminen todos los datos personales del usuario de nuestra aplicación.
                </p>
                <p className="mb-4">
                    La eliminación de cuenta es una acción irreversible, y una vez completada, no se podrá recuperar la información ni el acceso a la cuenta. Al confirmar la eliminación, el usuario acepta que se eliminarán sus datos personales de nuestra plataforma de manera permanente.
                </p>
                <p className="mb-4">
                    Si los usuarios tienen preguntas adicionales o desean obtener más información sobre el proceso de eliminación de cuenta, pueden ponerse en contacto con nosotros a través de nuestro correo de soporte: <a href="mailto:support@deportsen.com" className="text-blue-500 underline">support@deportsen.com</a>.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicies;