import React from 'react';
import { TextField } from '@mui/material';

const DeleteAccountComments = ({ comments, setComments, specificProblem, setSpecificProblem, additionalComments, setAdditionalComments, t }) => (
    <>
        <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">{(t('stay_with_us'))}</label>
            <TextField
                fullWidth
                multiline
                variant="outlined"
                rows={3}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                InputLabelProps={{ style: { whiteSpace: 'normal' } }}
                required
            />
        </div>
        <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">{(t('specific_problem'))}</label>
            <TextField
                fullWidth
                multiline
                variant="outlined"
                rows={3}
                value={specificProblem}
                onChange={(e) => setSpecificProblem(e.target.value)}
                InputLabelProps={{ style: { whiteSpace: 'normal' } }}
                required
            />
        </div >
        <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">{(t('additional_suggestions'))}</label>
            <TextField
                fullWidth
                multiline
                variant="outlined"
                rows={3}
                value={additionalComments}
                onChange={(e) => setAdditionalComments(e.target.value)}
                InputLabelProps={{ style: { whiteSpace: 'normal' } }}
                required
            />
        </div >
    </>
);

export default DeleteAccountComments;