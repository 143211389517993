import React from 'react';

const PhoneSection = ({ setLoading }) => {
    return (
        <div className="flex justify-center items-center h-auto pt-20 bg-[#33333313]">
            <div className="relative flex justify-center items-center h-auto">
                <img
                    src={require('../../assets/capturas/entreno-fisico.png')}
                    alt="Phone 2"
                    className="w-[38vw] lg:w-[20vw] md:w-[35vw] h-auto transition-transform transform hover:scale-125 z-10 hover:z-30 absolute -left-[22vw] lg:-left-[17vw] md:-left-[20vw]"
                    onLoad={() => {
                        setTimeout(() => {
                            setLoading(true)
                        }, 300);
                    }}
                />
                <img
                    src={require('../../assets/capturas/entreno.png')}
                    alt="Phone 1"
                    className="w-[42vw] lg:w-[22vw] md:w-[40vw] h-auto transition-transform transform hover:scale-110 z-20 relative"
                    onLoad={() => {
                        setTimeout(() => {
                            setLoading(true)
                        }, 300);
                    }}
                />
                <img
                    src={require('../../assets/capturas/entreno-habilidad.png')}
                    alt="Phone 3"
                    className="w-[38vw] lg:w-[20vw] md:w-[35vw] h-auto transition-transform transform hover:scale-125 z-10 hover:z-30 absolute -right-[22vw] lg:-right-[17vw] md:-right-[20vw]"
                    onLoad={() => {
                        setTimeout(() => {
                            setLoading(true)
                        }, 300);
                    }}
                />
            </div>
        </div>
    );
};


export default PhoneSection;