import React from 'react';
import googlePlayLogo from '../../assets/googlePlay.png';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
};

const FooterSection = () => {

    const { t } = useTranslation("home");

    return (
        <motion.div
            className="text-black py-8 px-2"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.6 }}
            variants={sectionVariants}
            style={{ backgroundColor: '#33333313' }}
        >

            <div className="container mx-auto w-[50%]">

                <h1 className="text-3xl font-bold text-center mb-4 mt-8">{t("download")}</h1>

                <p className="text-lg text-center">{t("get")}</p>

                <div className="flex justify-center mb-8 mt-4 hover:scale-110">
                    <a href="https://play.google.com/store/apps/details?id=com.hernan.dev.Deportsen">
                        <img src={googlePlayLogo} alt="Google Play Logo" className="h-16" />
                    </a>
                </div>

            </div>

        </motion.div>
    );
}

export default FooterSection;