import React from 'react';
import { Navigate } from 'react-router-dom';

const detectBrowserLanguage = () => {
    const lang = navigator.language || navigator.userLanguage;
    return lang.split('-')[0];
};

const RedirectToHome = () => {
    const defaultLanguage = 'en';
    const userLang = detectBrowserLanguage();
    const supportedLanguages = ['de', 'en', 'es', 'fr', 'hi', 'it', 'pt', 'ru', 'tr', 'uk'];

    const lang = supportedLanguages.includes(userLang) ? userLang : defaultLanguage;

    return <Navigate to={`/home/${lang}`} replace />;
};

export default RedirectToHome;
