import React from 'react';
import parametros from '../parametros/parametros.json';
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {

    const { t, i18n } = useTranslation("footer");

    return (
        <footer className="text-white py-10" style={{ backgroundColor: parametros.backGroundColor_azul_oscuro }}>
            <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center xl:px-20 px-1">
                <div className="mb-4 lg:mb-0">
                    <Link to="/terms-and-conditions" className="mr-4 text-white hover:text-gray-500 transition-colors">{t("text_terms_conditions")}</Link>
                    <span className="text-white mr-4">|</span>
                    <Link to="/privacy-policies" className="mr-4 text-white hover:text-gray-500 transition-colors">{t("text_privacy_policies")}</Link>
                </div>
                <div className="flex items-center">
                    <Link to={`/faq/${i18n.language}`} className="mr-4 text-white hover:text-gray-500 transition-colors">{t("text_faq")}</Link>
                    <span className="text-white mr-4">|</span>
                    <Link to={`/delete-account/${i18n.language}`} className="mr-4 text-white hover:text-gray-500 transition-colors">{t("text_delete_account")}</Link>
                </div>
            </div>
            <div className="container mx-auto flex flex-col lg:flex-row justify-center items-center xl:px-20 px-1 mt-10 mb-10">
                <div className="flex items-center">
                    <a href="https://tiktok.com/@deportsen" className="text-white mr-4 hover:text-gray-500 transition-colors"><FaTiktok size={22} /></a>
                    <a href="https://www.instagram.com/deportsen" className="text-white mr-4 hover:text-gray-500 transition-colors"><FaInstagram size={22} /></a>
                    <a href="https://www.facebook.com/deportsenApp" className="text-white mr-4 hover:text-gray-500 transition-colors"><FaFacebook size={22} /></a>
                    <a href="https://www.youtube.com/@deportsen" className="text-white hover:text-gray-500 transition-colors"><FaYoutube size={24} /></a>
                </div>
            </div>
            <div className="text-center mt-4">
                <p>&copy; {new Date().getFullYear()} {t("text_rights_reserved")}</p>
            </div>
        </footer>
    );
}

export default Footer;