import React from 'react';
import { TextField, FormControlLabel, Checkbox, Alert } from '@mui/material';

const DeleteAccountConfirmation = ({ deleteConfirmation, setDeleteConfirmation, userEmail, setUserEmail, alertDeleteConfirmation, t }) => (
    <>
        <FormControlLabel
            control={<Checkbox checked={deleteConfirmation} onChange={(e) => setDeleteConfirmation(e.target.checked)} />}
            label={t('confirm_deletion')}
        />

        {alertDeleteConfirmation === true && <Alert severity="warning">{t('check_confirm_deletion')}</Alert>}

        <div className="mt-4 mb-4">
            <label className="block text-gray-700 font-medium mb-2">{t('additional_info')}</label>
            <p>{t('additional_info_description')}</p>
            <p>{t('cancel_request')}</p>
        </div>

        <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">{t('associated_email')}</label>
            <TextField
                fullWidth
                label={t('email')}
                multiline
                variant="outlined"
                type="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value.toLocaleLowerCase().trim())}
                className="mb-4"
                required
            />
        </div>
    </>
);

export default DeleteAccountConfirmation;
