import React from 'react';
import { useTranslation } from 'react-i18next';

const HeaderSection = () => {
    const { t } = useTranslation("home");

    return (
        <div className="text-black py-8 mb-3.5 px-2" style={{ backgroundColor: '#33333313' }}>
            <div className="container mx-auto w-[80%]">

                <h1 className="text-3xl font-bold text-center mb-4 mt-8">{t("welcome")}</h1>

                <p className="text-lg text-center">{t("discover")}</p>

                <p className="text-lg text-center">{t("interface")}</p>

                <p className="text-lg text-center mb-8">{t("begin")}</p>
            </div>
        </div >
    );
}

export default HeaderSection;